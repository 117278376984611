<template>
  <v-dialog v-model="show" persistent max-width="500">
    <v-card :loading="loading">
      <v-toolbar dense dark flat tile color="secondary">
        <v-toolbar-title>Ajustes de impresión de ticket</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-2">
        <v-alert v-if="error" type="error" dense>{{ error }}</v-alert>
        <v-form ref="form" v-model="isFormValid" lazy-validation>
          <v-subheader class="px-0">Hoja de impresión</v-subheader>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.sheetConfig.width"
                outlined
                dense
                color="secondary"
                label="Largo de la hoja"
                :disabled="loading"
                prepend-icon="mdi-arrow-split-vertical"
                :rules="[$rules.required(), $rules.numeric()]"
              >
                <template #append>
                  <div class="pt-1">
                    <span>px</span>
                  </div>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.sheetConfig.height"
                outlined
                dense
                color="secondary"
                label="Alto de la hoja"
                :disabled="loading"
                prepend-icon="mdi-arrow-split-horizontal"
                :rules="[$rules.required(), $rules.numeric()]"
              >
                <template #append>
                  <div class="pt-1">
                    <span>px</span>
                  </div>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.sheetConfig.paddingX"
                outlined
                dense
                color="secondary"
                label="Margen de largo"
                :disabled="loading"
                prepend-icon="mdi-chevron-right"
                :rules="[$rules.required(), $rules.numeric()]"
              >
                <template #append>
                  <div class="pt-1">
                    <span>px</span>
                  </div>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.sheetConfig.paddingY"
                outlined
                dense
                color="secondary"
                label="Margen de alto"
                :disabled="loading"
                prepend-icon="mdi-chevron-down"
                :rules="[$rules.required(), $rules.numeric()]"
              >
                <template #append>
                  <div class="pt-1">
                    <span>px</span>
                  </div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-subheader class="px-0">Fuente</v-subheader>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.fontConfig.h1"
                outlined
                dense
                color="secondary"
                label="Tamaño del título"
                hint="Texto: Nota de reparación"
                persistent-hint
                :disabled="loading"
                :rules="[$rules.required(), $rules.numeric()]"
              >
                <template #append>
                  <div class="pt-1">
                    <span>px</span>
                  </div>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.fontConfig.h2"
                outlined
                dense
                color="secondary"
                label="Tamaño de títulos de secciones"
                hint="Ej: Cliente, Dispositivo, Pago"
                persistent-hint
                :disabled="loading"
                :rules="[$rules.required(), $rules.numeric()]"
              >
                <template #append>
                  <div class="pt-1">
                    <span>px</span>
                  </div>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.fontConfig.p"
                outlined
                dense
                color="secondary"
                label="Tamaño de datos generales"
                hint="Ej: Samsung Galaxy S21 Ultra"
                persistent-hint
                :disabled="loading"
                :rules="[$rules.required(), $rules.numeric()]"
              >
                <template #append>
                  <div class="pt-1">
                    <span>px</span>
                  </div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-subheader class="px-0"
            >Otras configuraciones de impresión</v-subheader
          >
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.logoWidthPercent"
                outlined
                dense
                color="secondary"
                label="Tamaño del logo"
                :disabled="loading"
                :rules="[
                  $rules.required(),
                  $rules.numeric(),
                  $rules.minValue(0),
                  $rules.maxValue(100),
                ]"
              >
                <template #append>
                  <div class="pt-1">
                    <span>%</span>
                  </div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-md-flex justify-end">
              <v-btn
                color="secondary"
                outlined
                class="mr-1 mb-1"
                :block="$vuetify.breakpoint.smAndDown"
                @click="$emit('close')"
              >
                <span>Cancelar</span>
              </v-btn>
              <v-btn
                color="secondary"
                :loading="loading"
                class="mb-1"
                :disabled="!isFormValid"
                :block="$vuetify.breakpoint.smAndDown"
                @click="submit()"
              >
                <v-icon small class="mr-2">mdi-content-save</v-icon>
                <span>Guardar ajustes</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    businessId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      isFormValid: true,
      error: null,
      form: {
        sheetConfig: {
          paddingX: 0,
          paddingY: 0,
          width: 0,
          height: 0,
        },
        fontConfig: {
          h1: 0,
          h2: 0,
          p: 0,
          a: 0,
        },
        logoWidthPercent: 0,
      },
    };
  },

  mounted() {
    this.loadCurrentConfig();
  },

  methods: {
    loadCurrentConfig() {
      this.loading = true;
      this.$axios
        .get(`/repairTicketConfig/business/${this.businessId}`)
        .then((response) => {
          this.form = response.data.repairTicketConfig;
        })
        .catch((error) => {
          if (error.response.status == 400)
            this.error = error.response.data.message;
          else
            this.error =
              "Error inesperado, por favor contacta al desarrollador";
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async submit() {
      await this.$refs.form.validate();
      if (this.isFormValid) this.saveChanges();
    },

    saveChanges() {
      this.loading = true;
      this.$axios
        .put(`/repairTicketConfig/business/${this.businessId}`, this.form)
        .then(() => {
          this.$emit("close");
        })
        .catch((error) => {
          if (error.response) this.error = error.response.data.message;
          else this.error = "Error inesperado, por favor contacta a soporte";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
